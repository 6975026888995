import styled from "styled-components"

export const ArticleTitle = styled.h1`
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 700;
  color: ${props => props.theme.colors.text};
  font-size: 1.6rem;
  margin: 0;
  line-height: 1.2;
  margin-bottom: 1rem;

  @media ${props => props.theme.sizes.tablet} {
    font-size: 2.1rem;
    margin: 0;
    line-height: 3rem;
    margin-bottom: 1.2rem;
  }
  @media ${props => props.theme.sizes.desktop} {
    font-size: 2.8rem;
    margin: 0;
    line-height: 3.75rem;
    margin-bottom: 1.2rem;
  }
`

export const ArticleIntro = styled.h2`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.primary};
  font-size: 1.2rem;
  margin: 0;

  p {
    line-height: 1.5;
  }
  strong {
    font-weight: 800;
  }

  @media ${props => props.theme.sizes.tablet} {
    font-size: 1.2rem;

    p {
      line-height: 2rem;
    }
  }
  @media ${props => props.theme.sizes.desktop} {
    font-size: 1.5rem;

    p {
      line-height: 2.7rem;
    }
  }
`

export const Article = styled.article`
  padding: 2rem 0 0;
  display: flex;
  flex-direction: column;
  max-width: 590px;
  margin: 0 auto;

  @media ${props => props.theme.sizes.tablet} {
    flex-direction: row;
    max-width: 600px;
  }

  @media ${props => props.theme.sizes.laptop} {
    max-width: 900px;
  }
  @media ${props => props.theme.sizes.laptopL} {
    max-width: 1100px;
  }
  @media ${props => props.theme.sizes.desktop} {
    max-width: 1280px;
  }

  .article {
    &-sidebar {
      order: 2;
      overflow: hidden;
      padding-left: 5%;
      width: 100%;

      @media ${props => props.theme.sizes.laptop} {
        min-width: 20%;
      }

      .artcile-Cta-image {
        margin: 0;
      }

      .as {
        &__sticky {
          color: ${props => props.theme.colors.text};

          .sticky-inner-wrapper {
            display: flex;
            flex-direction: column;

            img {
              width: 100%;
            }

            div {
              display: flex;
            }
          }
        }
      }
    }
    &-content {
      max-width: 75ch;
    }
  }
`

export const ArticleContent = styled.div`
  font-family: ${props => props.theme.fonts.secondary};

  .heading {
    margin-bottom: 3rem;
    font-family: ${props => props.theme.fonts.primary};

    a {
      display: none;
    }
  }

  h2 {
    font-size: 1.6rem;

    @media ${props => props.theme.sizes.tablet} {
      font-size: 2rem;
    }
    @media ${props => props.theme.sizes.desktop} {
      font-size: 2.62rem;
    }
  }
  h3 {
    font-size: 1.5rem;

    @media ${props => props.theme.sizes.tablet} {
      font-size: 2.32rem;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    color: ${props => props.theme.colors.text};
  }
  figure {
    margin: 1rem 0;

    &.aligncenter  {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 100%;
      height: auto;
    }

    figcaption {
      font-size: 0.85rem;
      font-weight: 400;
    }
  }
  p {
    font-size: 1.125rem;
    line-height: 1.78;
    font-weight: 300;

    @media ${props => props.theme.sizes.tablet} {
      font-size: 1.1875rem;
    }

  }
  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    position: relative;
    z-index: 1;
    line-height: 1.8;
    display: inline-block;
    font-weight: 400;

    &::before {
      content: "";
      height: 3px;
      width: 20px;
      background: ${props => props.theme.colors.primary};
      right: -5px;
      bottom: 0px;
      position: absolute;
      z-index: -1;
      transition: all 0.4s ease-out;
    }

    &:hover {
      color: ${props => props.theme.colors.text};
      &::before {
        width: calc(100% + 8px);
        height: 100%;
      }
    }
  }
  ul,
  ol {

    li {
      color: ${props => props.theme.colors.primary};
      font-weight: 700;
      line-height: 1.8;
      font-size: 1.125rem;

      @media ${props => props.theme.sizes.tablet} {
        font-size: 1.1875rem;
      }

      a {
        &::before {
          height: 2px;
        }
      }
    }
  }
`
export const ArticleFooter = styled.div`
  padding: 1rem 0 0;

  @media ${props => props.theme.sizes.tablet} {
    padding: 2rem 0 0;
  }
`
export const ArticleAuthor = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  .author {
    grid-column-start: 2;
    border: 0.4rem solid ${props => props.theme.colors.primary};
    position: relative;
    padding: 8%;
    font-family: ${props => props.theme.fonts.primary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;

    &__text {
      color: ${props => props.theme.colors.secondary};
      font-size: 1rem;
      font-weight: 700;
    }

    &__image {
      margin: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      img {
        /* width: 80%; */
        width: 100%;
        display: inherit;
      }
    }
    &__name {
      color: ${props => props.theme.colors.primary};
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 1;
      text-transform: uppercase;
      margin-top: 1rem;

      @media ${props => props.theme.sizes.laptop} {
        font-size: 1.4rem;
      }
      @media ${props => props.theme.sizes.desktop} {
        font-size: 1.8rem;
      }
    }
    &__position {
      color: ${props => props.theme.colors.text};
      font-size: 1rem;
      margin-bottom: 0.7rem;
    }
    &__content {
      color: ${props => props.theme.colors.text};
      font-size: 1rem;
    }
    &--elaboration {
      grid-column-start: 1;
    }
  }
`

export const ContactLink = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  font-weight: 600;
  font-size: 1.125rem;
  padding: 0 0.5rem;

  .contact__link {
    margin: 0.5rem 0;
  }

  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    position: relative;
    z-index: 1;
    line-height: 1.8;

    &::before {
      content: "";
      height: 3px;
      width: 20px;
      background: ${props => props.theme.colors.primary};
      right: -5px;
      bottom: 0px;
      position: absolute;
      z-index: -1;
      transition: all 0.4s ease-out;
    }

    &:hover {
      color: ${props => props.theme.colors.text};
      &::before {
        width: calc(100% + 8px);
        height: 100%;
      }
    }
  }
`
