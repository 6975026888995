import React from "react"
import Layout from "../../components/layout"
import { SEO } from "../../components/seo"

import { GatsbyImage, getImage } from "gatsby-plugin-image"


import Container from "../../components/container.js"
import {
  Article,
  ArticleTitle,
  ArticleIntro,
  ArticleContent,
  ArticleFooter,
  ArticleAuthor,
  ContactLink,
} from "./Post.style.js"

const BlogPost = props => {
  const { title } = props.pageContext
  const { content } = props.pageContext
  const { ACF_post } = props.pageContext
  const SEO = props.pageContext.seo



  const AuthorName = ACF_post.author.title
  const AuthorPosition = ACF_post.author.ACF_team.position
  const AuthorContent = ACF_post.author.content
  const AuthorImage =
    ACF_post.author.featuredImage.node.localFile.childImageSharp.fixed

  return (
    <Layout seoData={SEO} seoUrl={props.pageContext.uri}>
      <Container>
        <Article className="article">
          <div id="sidebar" className="article-sidebar">
            <div>
              <ArticleAuthor>
                <div className="author">
                  <div className="author__text">Autor</div>
                  <div className="author__name">{AuthorName}</div>
                  <div className="author__position">{AuthorPosition}</div>
                  <figure className="author__image">
                    <GatsbyImage
                      image={getImage(ACF_post.author.featuredImage.node.localFile)}
                      alt={ACF_post.author.featuredImage.node.altText || "Author Image"}
                      title={ACF_post.author.featuredImage.node.title || "Author Image"}
                    />

                  </figure>
                </div>
              </ArticleAuthor>

              {ACF_post.elaboration === null ? (
                <></>
              ) : (
                <ArticleAuthor>
                  <div className="author author--elaboration">
                    <div className="author__text">Opracowanie merytoryczne</div>
                    <div className="author__name">
                      {ACF_post.elaboration.title}
                    </div>
                    <div className="author__position">
                      {ACF_post.elaboration.ACF_team.position}
                    </div>

                    <figure className="author__image">
                      <GatsbyImage
                        image={getImage(ACF_post.elaboration.featuredImage.node.localFile)}
                        alt={ACF_post.elaboration.featuredImage.node.altText || "Elaboration"}
                        title={ACF_post.elaboration.featuredImage.node.title || "Elaboration"}
                      />
                    </figure>
                  </div>
                </ArticleAuthor>
              )}
            </div>
{/*
              <Sticky
                top={100}
                bottomBoundary="#sidebar"
                className="as__sticky"
              >
                {ACF_post.ctaBanner && (
                 <AnchorLink
                  to={ACF_post.ctaLink}
                  className="artcile-Cta"
                >
                  <figure className="artcile-Cta-image">
                    <GatsbyImage
                      image={getImage(ACF_post.ctaBanner.localFile)}
                      alt={ACF_post.ctaBanner.altText || "CTA banner"}
                      title={ACF_post.ctaBanner.title || "CTA banner"}
                    />
                  </figure>
                </AnchorLink>

                )}

                {!ACF_post.ctaBanner &&  (

                <ContactLink>
                  <span>Czas na zmiany? Porozmawiajmy o Twojej marce.</span>
                  <div className="contact__link">
                    <AnchorLink to="#Kontakt">szybki kontakt >></AnchorLink>
                  </div>
                </ContactLink>

                )}
              </Sticky> */}

          </div>
          <div className="article-content">
            <div className="PostHero">
              <ArticleTitle
                dangerouslySetInnerHTML={{ __html: title }}
              ></ArticleTitle>
              <ArticleIntro
                dangerouslySetInnerHTML={{ __html: ACF_post.headerparagraph }}
              />
            </div>
            <ArticleContent dangerouslySetInnerHTML={{ __html: content }} />
            {/* <ArticleFooter>
              <PostCategories list={categories.nodes} />
            </ArticleFooter> */}
          </div>
        </Article>
      </Container>
    </Layout>
  )
}
export default BlogPost

export const Head = props => {
  return (
   <SEO title={props.pageContext.seo.title} description={props.pageContext.seo.opengraphDescription} image={props.pageContext.seo?.opengraphImage?.localFile.childImageSharp.fixed.src} pathname={props.pageContext.seo.opengraphUrl} />
  )
}
